import React, { Fragment, useState } from 'react';

const DIY = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (imageSrc) => {
    setSelectedImage(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  return (
    <Fragment>
      <div className="content">
        <h1>DIY Heritage</h1>
<p>DIY Heritage is a digital platform I created to share and document street art, personal memories, and DIY attempts of mine.
  This project was born out of my desire to explore full-stack development while also 
  preserving cultural values that might otherwise fade away.</p>

  <p>Explore the site: <a target="_blank" 
  rel="noopener noreferrer" href='https://www.diyheritage.com'>www.diyheritage.com</a> </p>
       
      </div>
     
    </Fragment>
  );
};

export default DIY;

