const clogPosts = [
    {
      id: 1,
      title: 'First Log',
      date: '2024-10-26',
      content: 'Hello World! (Adettendir)',
    },
    {
      id: 2,
      title: 'Second Log',
      date: '2024-10-26',
      content: 'Still working!',
    },
    {
      id: 3,
      title: 'Long text',
      date: '2024-10-26',
      content: 'Nulla facilisi. Nulla maximus urna laoreet, posuere justo ut, varius eros. Sed neque neque, ullamcorper a vulputate et, porttitor id nisl. Fusce eget elit at metus sodales vehicula. Duis varius est vitae tincidunt mattis. Praesent eros odio, vehicula eget ex in, tincidunt fermentum orci. Nam ac dolor et turpis feugiat venenatis. Suspendisse sem elit, elementum interdum lacus eu, fermentum consequat tellus. Nam tempor libero sed maximus aliquam. Pellentesque finibus libero ac nulla facilisis, sit amet rutrum diam tempus. Aenean id dolor in orci varius ullamcorper quis et elit. Nunc ut efficitur quam, eu lobortis sapien. Vivamus consequat, mi nec pretium malesuada, dui dolor imperdiet orci, at porttitor enim turpis non nisl.',
    },
    {
      id: 5,
      title: 'About "i"',
      date: '2024-11-06',
      content: 'Why there is no point on the top of "i" when capital? Why cant i write i lower case? Oh, i did. İ did another thing!',
    },
   /*  {
      id: 6,
      title: 'Temporariness',
      date: '2024-11-10',
      content: 'We can leave our stones aside. Leave the gravity for some time. Pick them up again if we want.'
    },  */
    {
      id: 7,
      title: 'Just a thought',
      date: '2024-11-22',
      content: 'Why not text/rpg/html...???'
    },
    {
      id: 8,
      title: 'Smiley',
      date: '2024-11-23',
      content: 'Gpt sent me its first message with smiley :)'
    },
    {
      id: 9,
      title: 'Candle art',
      date: '2024-11-23',
      content: 'Drop some candle on a surface intentionally or by mistake. Carve it in your prefered temperature.'
    },
    {
      id: 10,
      title: 'Alive',
      date: '2024-12-01',
      content: 'A humble attempt.'
    },
    {
      id: 11,
      title: 'Jack of all trades',
      date: '2024-12-05',
      content: 'Archaeology, archive, halı, kilim, travel.'
    },
    {
      id: 12,
      title: 'svenska',
      date: '2025-01-21',
      content: 'Jag behöver en kopp kaffe.'
    },{
      id: 13,
      title: 'DIY Heritage',
      date: '2025-01-31',
      content: 'It is on. https://www.diyheritage.com/'
    },
    

  ];

export default clogPosts;